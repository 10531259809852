.social-button{
    @extend %buttonReset;
    @extend %boxShadow;
    cursor: pointer;
    font-weight: bold;
    color: $white;
    font-size: rem(14);
    border-radius: 5px;
    padding: rem(6) rem(15);
    align-items: center;
    display: flex;
    white-space: nowrap;

    @include breakpoint(400){
        display: inline-flex;
    }

    &+.social-button{
        margin-top: rem(10);

        @include breakpoint(400){
            margin-top: 0;
            margin-left: rem(10);
        }

    }

    &--facebook{
        background-color: #3B5998;

        svg{
            width: .5em;
        }
    }

    &--twitter{
        background-color: #1DA1F2;
        //background-color: #1781C2;

        svg{
            width: 1em;
        }
    }

    svg{
        height: 1em;
        margin-right: rem(10);
    }

    &__center{
        display: flex;
        flex-wrap: wrap;
        margin: 1.5em 0;
        justify-content: center;
    }

}