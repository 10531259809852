/* class applies to select element itself, not a wrapper element */
.select {
	display: block;
	font-size: rem(16);
	font-family: $baseFont;
	color: $black;
	line-height: 1.1;
	padding: .7em 1.7em .7em .8em;
	width: 100%;
	max-width: 100%; /* useful when width is set to anything other than 100% */
	box-sizing: border-box;
	margin: 1em 0 1.5em;
	border: none;
	border-radius: 5px;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
    background-color: #fff;
    @extend %boxShadow;
	/* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
		for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
		
	*/ 
	background-image: url('../svg/arrow.svg');
	background-repeat: no-repeat;
	/* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
	background-position: right .9em top 50%;
	/* icon size, then gradient */
	background-size: rem(13) rem(20);

}
/* Hide arrow icon in IE browsers */
.select::-ms-expand {
	display: none;
}
/* Hover style */
.select:hover {
	border-color: #888;
}
/* Focus style */
.select:focus {
	border-color: none;
	/* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
	color: #222;
	outline: none;

	&._secondary{
		color: #fff;
	}

}

/* Set options to normal weight */
.select option {
	font-weight:normal;
}

/* Support for rtl text, explicit support for Arabic and Hebrew */
*[dir="rtl"] .select, :root:lang(ar) .select, :root:lang(iw) .select {
	background-position: left .7em top 50%, 0 0;
	padding: .6em .8em .5em 1.4em;
}
