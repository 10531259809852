// =========================================================================
// Base - Defaults and Content
// =========================================================================

*, *:before, *:after {
	box-sizing: border-box;
}

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  height: 100%;
}

body {
  font-family: $baseFont;
  color: $black;
  @include x-rem(font-size, 16px);
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  background-color: $lightestGrey;
  height: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  transition: all, 0.2s, linear;
  color: $primaryColor;
}

a:visited {

}

a:hover {
	outline: none;
}

a:active {
	outline: none;
}

a[href^=tel] {
	text-decoration: inherit;
	color: inherit;
}