.checklist{
    list-style: none;
    margin: 2em 0 0;
    padding: 0;

    li{
        margin-bottom: 4px;

        span {
            position: relative;
          }
          
          span::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            height: 3px;
            background-color:$primaryColor;
            transform-origin: center right;
            transform: scaleX(0) translateY(-50%);
            transition: transform 0.3s ease;
          }
          
        &.checked{

            span::before {
                transform-origin: center left;
                transform: scaleX(1) translateY(-50%);
            }
    
            svg path{
                fill: #A7D550;
            }

            img{
                filter: grayscale(1);
            }

        }

        button{
            @extend %buttonReset;
            display: flex;
            width: 100%;
            background-color: $white;
            padding: rem(8) rem(15);
            text-align: left;
            cursor: pointer;
            align-items: center;

            img{
                width: rem(20);
                height: rem(20);   
                margin-right: rem(10);      
                border-radius: 3px;   
                transition: all 0.5s ease;
            }

            svg{
                width: rem(16);
                height: rem(16);
                margin-left: auto;
                path{
                    transition: all 0.3s ease;
                }
                
            }

        }

    }

}