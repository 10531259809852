// =========================================================================
// Forms
// =========================================================================

form {

    label{
        font-weight: bold;
        display: inline-block;
        margin-top: 1.5em;
    }
    
}

legend {
    width: 100%;
    display: table;
}

fieldset {
    border: 0;
    padding: 0.01em 0 0 0;
    margin: 0;
    min-width: 0;
}

body:not(:-moz-handler-blocked) fieldset {
    display: table-cell;
}

// =========================================================================
// Placeholders
// =========================================================================

::-webkit-input-placeholder {
	color: $black;
}

:-moz-placeholder {
	color: $black;
}

::-moz-placeholder {
	color: $black;
	opacity: 1;
}

:-ms-input-placeholder {
	color: $black;
}