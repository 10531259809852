.result-txt{
    font-size: rem(32);
    font-weight: 700;
    text-align: center;
    line-height: 1.25;

    span{
        display: block;
        text-shadow: 0 1px 1px rgb(81,81,81);
    }

    &__chevron svg{
        height: 1em;
        //filter: drop-shadow( 0 1px 1px rgb(81,81,81));
        vertical-align: middle;

        path{
            fill: $primaryColor;
        }
    }

}

.result-img{
    padding-top: 1em;
    text-align: center;
}

.result-list{
    list-style: none;
    padding: 0;

    li{
        vertical-align: middle;
        margin-bottom: rem(8);
        font-weight: bold;

        a{
            
        }

        img{
            width: rem(23);
            height: rem(23);
            margin-right: rem(10);
            vertical-align: middle;
            border-radius: 3px;   
        }
    }

}