// =========================================================================
// Variables
// =========================================================================

// =========================================================================
// Colours
// =========================================================================

$white: #FFF;
$black: #515151;

// Greys
$lightestGrey: #F2F2F2;
$lightGrey: #BFBFBF;
$mediumGrey: #A5A5A5;
$darkGrey: #707070;
$darkestGrey: #515151;

$highlightColor: #E4EBEC;

// Colour (green)
$primaryColor: #3071A9;

// Colour (blue)
$secondaryColor: #1141D1;

// Colour (blue)
$tertiaryColor: #330099;

// Danger
$dangerColor: #EF0B2E;

// Warning
$warningColor: #FFB300;

// Success
$successColor: #87C80A;

// =========================================================================
// Spacing Rules
// =========================================================================


// =========================================================================
// Typeface Rules
// =========================================================================

$baseFont: 'Open Sans', Helvetica, Arial, sans-serif;

%titleFont {
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  font-weight: normal;
  line-height: 1;
}

// @extend %titleFont;