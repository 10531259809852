// =========================================================================
// Animations
// =========================================================================

@keyframes float {
	0% {
		transform: translate3d(0,0,0);
	}
	50% {
		transform: translate3d(0,5px,0);
	}
}

@keyframes cd-bounce {
	0% {
		opacity: 0;
		transform: scale(0.5);
	}
	60% {
		opacity: 1;
		transform: scale(1.2);
	}
	100% {
		transform: scale(1);
	}
}
