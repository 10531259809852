.flags{
    display: flex;
    margin-left: auto;

    a{
        margin-left: rem(10);

        img{
            width: rem(37);
            height: rem(28);
        }

    }



}