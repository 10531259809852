// =========================================================================
// Buttons
// =========================================================================

.button {
    @extend %buttonReset;
    @extend %boxShadow;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    vertical-align: middle;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    border: none;
    text-decoration: none;
    background-color: $primaryColor;
    color: $white;
    border-radius: 5px;
    font-family: $baseFont;
    font-weight: bold;
    font-size: rem(18);
    padding: .5em 2em;

    &--fill{
        width: 100%;
    }

    &--center{
        left: 50%;
        transform: translateX(-50%);
    }

    &--chevron{
        padding: .5em 4em .5em 2em;
        margin-top: 1.5em;

        &::after{
            content: url('../svg/chevron.svg');
            position: absolute;
            width: rem(18);
            height: rem(21);
            right: .9em;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.25s linear;
        }

        &.active::after{
            transform: translateY(-50%) scaleY(-1);
        }
    }
    
}
