// =========================================================================
// Typography - Resets
// =========================================================================

b, strong{
    font-weight: bold;
}

p {
    margin: 0 0 1em;
    line-height: 1.375;
}


h1, h2, h3, h4, h5, h6 {
    margin: 0 0 .5em;
    @extend %titleFont;
}

h1{
    font-size: rem(28);
}

address {
    font-style: normal;
}
