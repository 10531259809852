.colour-picker{

    p{
        margin-bottom: 1em;
    }

    &__categories{
        display: flex;
    }

    &__category{
        width: rem(32);
        height: rem(32);
        @extend %boxShadow;
        border-radius: 32px;
        margin-right: rem(10);
        cursor: pointer;

        &.White{ background-color: #fff; }
        &.Red{ background-color: #DA2C2C; }
        &.Brown{ background-color: #B8864A; }
        &.Yellow{ background-color: #F3E33F; }
        &.Green{ background-color: #A7D550; }
        &.Blue{ background-color: #5F95F5; }
        &.Purple{ background-color: #B577E9; }

        &.active{
            border: 3px solid $primaryColor;   
            //box-shadow: 0 1px 5px rgba(48, 113, 169, 0.5);
        }

    }

    &__colours{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 1em rem(-3) 0;
    }

    &__colour{
        width: rem(26);
        height: rem(26);
        @extend %boxShadow;
        margin: rem(3);
        cursor: pointer;

            &.active{
             border: 2px solid $white;
             box-shadow: 0 1px 5px rgba(48, 113, 169, 0.5);
            }

    }

}