// =========================================================================
// Mixins
// =========================================================================

// =========================================================================
// Media Queries - @include breakpoint(1024) {}
// =========================================================================

@mixin breakpoint($point, $max: false) {
    
        $rem: ($point / 16 * 1rem);

	@if $max == true {
		@media (max-width: #{$rem})  { @content; }
	}
	@else{
		@media (min-width: #{$rem})  { @content; }
	}

}

@function rem($px) {  
    @return ($px/16*1rem);
}

// =========================================================================
// PX - REM
// =========================================================================

/*
 * a small mixin for easy use of rem with px as fallback
 * usage: @include x-rem(font-size, 14px)
 * usage: @include x-rem(margin, 0 12px 2 1.2)
 * usage: @include x-rem(padding, 1.5 24px)
 *
 */
@mixin x-rem($property, $values) {
  // Create a couple of empty lists as output buffers.
  $base-font-size: 16px; // should be consistent with your html/body font-size
  $px-values: ();
  $rem-values: ();

  // Loop through the $values list
  @each $value in $values {
    // For each property value, if it's in rem or px, derive both rem and
    // px values for it and add those to the end of the appropriate buffer.
    // Ensure all pixel values are rounded to the nearest pixel.
    @if $value == 0 or $value == 0px {
      // 0 -- use it without a unit
      $px-values: join($px-values, 0);
      $rem-values: join($rem-values, 0);
    } @else if type-of($value) == number and not unitless($value) and (unit($value) == px) {
      // px value given - calculate rem value from base-font-size
      $new-rem-value: $value / $base-font-size;
      $px-values: join($px-values, round($value));
      $rem-values: join($rem-values, #{$new-rem-value}rem);
    } @else {
      // unitless value - use those directly as rem and calculate the px-fallback
      $px-values: join($px-values, round($value * $base-font-size));
      $rem-values: join($rem-values, #{$value}rem);
    }
  }

  // output the converted rules
  #{$property}: $px-values;
  #{$property}: $rem-values;
}

// =========================================================================
// States - @include state() {}
// =========================================================================

@mixin state() {
	&:hover,
	&:active,
	&:focus {
		@content;
	}
}

// =========================================================================
// RGBA - @include rgba(background-color, $red, 0.8, $red);
// =========================================================================

@mixin rgba($property, $color: #000, $value: 0.5, $fallback: #000) {
	#{$property}: rgb(red($fallback), green($fallback), blue($fallback));
	#{$property}: rgba($color, $value);
}

// =========================================================================
// Border Radius - @include border-radius(5px); @include border-top-radius(10px);
// =========================================================================

@mixin border-radius($radius) {
	border-radius: $radius;
	background-clip: padding-box;
}

@mixin border-top-radius($radius) {
	border-top-right-radius: $radius;
	border-top-left-radius: $radius;
	background-clip: padding-box;
}
@mixin border-right-radius($radius) {
	border-bottom-right-radius: $radius;
	border-top-right-radius: $radius;
	background-clip: padding-box;
}
@mixin border-bottom-radius($radius) {
	border-bottom-right-radius: $radius;
	border-bottom-left-radius: $radius;
	background-clip: padding-box;
}
@mixin border-left-radius($radius) {
	border-bottom-left-radius: $radius;
	border-top-left-radius: $radius;
	background-clip: padding-box;
}

// =========================================================================
// Retina Images - @include image-2x('logo2x.png', 100px, 25px);
// =========================================================================

@mixin image-2x($image, $width, $height) {
	@media (min--moz-device-pixel-ratio: 1.3),
			(-o-min-device-pixel-ratio: 2.6/2),
			(-webkit-min-device-pixel-ratio: 1.3),
			(min-device-pixel-ratio: 1.3),
			(min-resolution: 1.3dppx) {
		background-image: url($image);
		background-size: $width $height;
	}
}

// =========================================================================
// Arrows - @include arrow(15px, #1e252b);
// =========================================================================

@mixin arrow($size, $background) {
	background: $background;
	display: inline-block;
	padding: $size;
	position: relative;
	margin-left: $size;
	&:before {
		content: '';
		position: absolute;
		left: -$size;
		top: 50%;
		transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		border-top: ($size / 2) solid transparent;
		border-right: $size solid $background;
		border-bottom: ($size / 2) solid transparent;
	}
}

// =========================================================================
// Clearfix - @include clearfix();
// =========================================================================

@mixin clearfix() {
    &:before,
    &:after {
        content: '';
        display: table;
    }
    &:after {
        clear: both;
    }
}

// =========================================================================
// Hide Text - @include hide-text();
// =========================================================================

@mixin hide-text {
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
}

// =========================================================================
// Hide Visually - @include hide-visually();
// =========================================================================

@mixin hide-visually {
    clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important;
}

// =========================================================================
// Gradients - @include gradient-left-gray();
// =========================================================================

@mixin gradient-tran-gray {
    background: -moz-linear-gradient(left,  rgba(0,0,0,0) 0%, rgba(197,197,197,1) 50%, rgba(0,0,0,0) 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(0,0,0,0)), color-stop(50%,rgba(197,197,197,1)), color-stop(100%,rgba(0,0,0,0)));
    background: -webkit-linear-gradient(left,  rgba(0,0,0,0) 0%,rgba(197,197,197,1) 50%,rgba(0,0,0,0) 100%);
    background: -o-linear-gradient(left,  rgba(0,0,0,0) 0%,rgba(197,197,197,1) 50%,rgba(0,0,0,0) 100%);
    background: -ms-linear-gradient(left,  rgba(0,0,0,0) 0%,rgba(197,197,197,1) 50%,rgba(0,0,0,0) 100%);
    background: linear-gradient(to right,  rgba(0,0,0,0) 0%,rgba(197,197,197,1) 50%,rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c5c5c5', endColorstr='#c5c5c5',GradientType=1 );
}

.ie9 .gradient-tran-gray {
    filter: none;
}

// =========================================================================
// Grayscale @include grayscale-enabled();
// =========================================================================

@mixin grayscale-enabled {
    filter: url('data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale');
    filter: gray;
    -webkit-filter: grayscale(100%);
}

@mixin grayscale-disabled {
    filter: url('data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale');
    -webkit-filter: grayscale(0%);
}