#root .app{
    min-height: 100%;
    max-width: rem(728);
    display: grid;
    padding: rem(10) rem(15);
    margin: 0 auto;

    header{
        display: flex;
        align-items: center;
        margin-bottom: 1.5em;

        .home svg{
            width: rem(32);
            height: rem(28);
        }

    }

    .adsbygoogle{
        position: relative;
        width: 100%;
        height: 50px;
        display: block;

        @include breakpoint(500){
            left: 50%;
            transform: translateX(-50%);
            width: 468px;
            height: 60px;
        }

        @include breakpoint(800){
            width: 728px;
            height: 90px;
        }

    }

    main{
        margin-top: 1.5em;

        .content{
            max-width: rem(480);
            margin: 0 auto;

            .how-it-works{
                @extend %buttonReset;
                display: inline-block;
                margin: 1.5em 0 1em;
                text-decoration: underline;
                cursor: pointer;
                color: $primaryColor;
            }

        }

    }

    footer{
        font-size: rem(14);
        display: grid;

        .adsbygoogle{
            margin: 1.5em 0;
        }

        nav{
            padding-bottom: 1.5em;
        }

        .trademark{
            margin-bottom: 0;
        }

        @include breakpoint(460){
            grid-template-columns: auto auto;
            grid-template-rows: auto auto auto;

            .adsbygoogle{
                grid-area: 1 / 1 / 2 / 3;
            }
    
            p.copyright{
                float: left;
                grid-area: 2 / 1 / 3 / 2;
            }
    
            nav{
                float: right;
                grid-area: 2 / 2 / 3 / 3;
                text-align: right;
            }
    
            p.trademark{
                clear: both;
                grid-area: 3 / 1 / 4 / 3;
            }
            
        }

    }

}
